main {
  align-content: center;
  box-sizing: border-box;
  display: grid;
  font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  hyphens: auto;
  line-height: 1.65;
  margin: 0 auto;
    max-width: 53rem;
    min-height: 100vh;
    padding: 62px 27px;
  text-align: center;
   background:#fff!important;
}
h1 {
  font-size: 45px;
}
h2 {
  margin-top: 1.5em;
}
p {
  font-size: 16px;
}
a {
  border-bottom: 1px solid white;
  color: #0076ff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}
a:hover {
  border-bottom: 1px solid #0076ff;
}
code,
pre {
  color: #d400ff;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 0.92em;
}
code:before,
code:after {
  content: '\`';
}

.desc{
  text-align: justify;
    font-size: 1.5rem;
    color: #76777f;}


   
   @media only screen and (max-width: 600px){
      main{
        width: 92%;
        backgound:red;
      }
        h1{
        font-size: 27px;
            margin-bottom: 0;
        }
     .desc{
         font-size: 1rem;
     }
    }
